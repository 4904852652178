import { useEffect, useMemo, useRef } from "react";

const GoogleMap = () => {
  const ref = useRef();
  const bonadermaPosition = useMemo(
    () => ({ lat: 41.992516, lng: 21.421929 }),
    []
  );

  useEffect(() => {
    // @ts-ignore
    new window.google.maps.Map(ref.current, {
      center: bonadermaPosition,
      zoom: 19,
    });
  }, [bonadermaPosition]);

  // @ts-ignore
  return <div ref={ref} id="map" className="h-full w-full" />;
};

export default GoogleMap;
