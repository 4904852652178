import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationMK from "i18n/mk/translation.json";
import translationEN from "i18n/en/translation.json";

const resources = {
  mk: {
    translation: translationMK,
  },
  en: {
    translation: translationEN,
  },
};

void i18n.use(initReactI18next).init({
  resources,
  lng: "mk",
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
