import "../Tailwind.css";
import BlurBubble from "home/BlurBubble";
import BonadermaWithText from "image/logo/bonaderma-with-text.svg";
import Face from "image/face.svg";
import Hair from "image/hair.svg";
import React, { useEffect } from "react";
import World from "image/buildings.svg";
import Heart from "image/heart.svg";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "map/Map";
import Person from "image/person.svg";
import MobilePhone from "image/mobile-phone.svg";
import Clock from "image/clock.svg";
import { useTranslation } from "react-i18next";
import Email from "image/email.svg";

const HomePage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const isEnglishLanguageInUrl = window.location.href.includes("en");
    if (isEnglishLanguageInUrl) {
      void i18n.changeLanguage("en");
    }
  }, [i18n]);

  return (
    <>
      <div className="h-screen">
        <header className="bg-white">
          <nav
            className="absolute mx-auto w-full bg-bonadermaGreen p-2 md:px-6"
            aria-label="Global"
          >
            <div className="mx-auto flex max-w-7xl items-center justify-between">
              <div className="flex">
                <p className="text-white">
                  {t("Lidija")}{" "}
                  <span className="hidden md:inline">
                    {t("Goleva Mishevska")}
                  </span>
                </p>
              </div>
              <div className="flex">
                <a className="text-white underline" href="tel:+389070257668">
                  {t("Phone number")}
                </a>
              </div>
            </div>
          </nav>
        </header>

        <div className="hero grid h-screen place-content-center place-items-center px-2 md:px-6">
          <BlurBubble />

          <img src={BonadermaWithText} alt="" className="" />

          <h1 className="mt-2 text-center text-sm text-bonadermaBlue md:mt-4">
            {t("Site slogan")}
          </h1>
        </div>

        <div className="section-navigation relative top-[-90px] mx-auto flex max-w-7xl items-center justify-evenly">
          <a
            href="#section-about-us"
            className="rounded-md border border-bonadermaBlue px-3.5 py-2.5 text-sm font-semibold text-bonadermaBlue shadow-sm hover:bg-bonadermaBlue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bonadermaBlue"
          >
            {t("About us")}
          </a>
          <a
            href="#section-contact"
            className="rounded-md border border-bonadermaBlue px-3.5 py-2.5 text-sm font-semibold text-bonadermaBlue shadow-sm hover:bg-bonadermaBlue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bonadermaBlue"
          >
            {t("Contact")}
          </a>
          <a
            href="https://blog.bonaderma.xyz"
            className="rounded-md border border-bonadermaBlue px-3.5 py-2.5 text-sm font-semibold text-bonadermaBlue shadow-sm hover:bg-bonadermaBlue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bonadermaBlue"
          >
            {t("Blog")}
          </a>
        </div>
      </div>

      <div className="section-under-hero md:grid md:grid-cols-2">
        <div className="mx-auto flex h-[50vh] flex-col items-center justify-center bg-bonadermaBlue px-4 text-center text-white md:px-6">
          <img src={Hair} className="h-32 w-32" alt="" />
          <p className="mt-4 font-bold text-blue-50">
            {t("Dermatological exams")}
          </p>
          <p className="mt-2 text-blue-50">{t("Appropriate treatment")}</p>
        </div>

        <div className="mx-auto flex h-[50vh] flex-col items-center justify-center bg-bonadermaGreen px-4 text-center text-white md:px-6">
          <img src={Face} className="h-32 w-32" />
          <p className="mt-4 font-bold text-green-50">
            {t("Dermatological esthetically services")}
          </p>
          <p className="mt-2 text-green-50">{t("Examination of acne")}</p>
        </div>
      </div>

      <div
        className="bg-gray-50 px-4 py-10 md:px-6 lg:py-20"
        id="section-about-us"
      >
        <h2 className="mb-8 text-center text-3xl font-extrabold text-bonadermaBlue lg:mb-20">
          {t("About us")}
        </h2>

        <div className="mx-auto max-w-7xl md:grid md:grid-cols-2">
          <div className="flex flex-col items-center justify-center text-center md:border-r md:pr-4">
            <img src={World} alt="" className="h-32 w-32 text-white" />
            <p className="text-gray-600">{t("Description about Bonaderma")}</p>
          </div>
          <div className="mt-8 flex flex-col items-center justify-center text-center md:mt-0 md:pl-4">
            <img src={Heart} alt="" className="h-32 w-32" />
            <p className="text-gray-600">{t("What we offer")}</p>
          </div>
        </div>
      </div>

      <div className="pb-8 pt-10 lg:pb-20 lg:pt-16" id="section-contact">
        <h2 className="mb-8 text-center text-3xl font-extrabold text-bonadermaBlue lg:mb-20">
          {t("Contact")}
        </h2>
        <div className="mx-auto max-w-7xl px-4 md:mb-8 md:mt-12 md:grid md:grid-cols-2 md:px-6">
          <div className="md:mr-4 md:border-r lg:border-r-0">
            <div className="flex items-center lg:mb-6">
              <img src={Person} alt="" className="mr-2 h-8 w-8" />
              <p className="text-gray-600">{t("Full name")}</p>
            </div>

            <div className="mt-2 flex items-center lg:mb-6">
              <img src={MobilePhone} alt="" className="mr-2 h-8 w-8" />
              <a
                className="text-bonadermaBlue underline"
                href="tel:+389070257668"
              >
                {t("Phone number")}
              </a>
            </div>

            <div className="mt-2 flex items-center">
              <img src={Email} alt="" className="mr-2 h-8 w-8" />
              <a
                href="mailto:info@bonaderma.xyz"
                className="text-bonadermaBlue underline"
              >
                info@bonaderma.xyz
              </a>
            </div>
          </div>

          <div>
            <div className="mt-8 flex flex-col items-center justify-center md:mt-0">
              <img src={Clock} alt="" className="h-12 w-12" />
              <p className="text-gray-600 lg:mb-6">{t("Working hour")}</p>
            </div>

            <div className="mt-4 flex justify-between lg:justify-around">
              <span className="text-gray-600">{t("Tuesday to Saturday")}</span>
              <span className="text-gray-600">{t("Eleven to seventeen")}</span>
            </div>

            <div className="flex justify-between lg:justify-around">
              <span className="text-gray-600">{t("Sunday to Monday")}</span>
              <span className="text-gray-600">{t("Not working")}</span>
            </div>

            <div className="mt-4 flex justify-center text-xs text-bonadermaGreen md:text-sm ">
              <p>{t("Examinations and treatments")}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="section-location bg-gray-50 pt-8 lg:pt-20"
        id="section-location"
      >
        <h2 className="mb-8 text-center text-3xl font-extrabold text-bonadermaBlue">
          {t("Location")}
        </h2>

        <p className="px-4 text-center text-gray-600 md:px-6">
          {t("Description about location")}
        </p>

        <div className="mt-4 h-[75vh] w-full lg:mt-12">
          <Wrapper apiKey="AIzaSyDKpEqOCDApSUbFfIIsu00lEYuQIe-OBGQ">
            <GoogleMap />
          </Wrapper>
        </div>
      </div>

      <footer className="mb-8 mt-8 flex justify-center lg:py-20">
        <p className="text-gray-600">
          Copyright © {new Date().getFullYear()} Bonaderma. All rights reserved.
        </p>
      </footer>
    </>
  );
};
export default HomePage;
